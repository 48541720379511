html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    max-width: 100%;
    overflow-x: hidden;
}

.text-blue{
    color: #398FE5;
}

*,
*::after,
*::before {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

a {
    text-decoration: none;
}

:root {
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}

body {
    background-color: #000000;
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    line-height: 1.7;
    color: #263238;
    font-weight: 400;
    max-width: 100%;
    overflow-x: hidden;
}

button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

button:focus {
    outline: none;
    box-shadow: none;
}

h1 {
    font-weight: 400;
    font-size: 75px;
    line-height: 80px;
    color: #ffffff;
    letter-spacing: -3.2px;
}

h2 {
    font-weight: 500;
    font-size: 40px;
    line-height: 70px;
    letter-spacing: 0px;
    color: #ffffff;
}

h3 {
    font-weight: 500;
    font-size: 30px;
    line-height: 46px;
    letter-spacing: 1px;
    color: #ffffff;
}

h4 {
    font-weight: 700;
    font-size: 27px;
    line-height: 1.4;
}

img {
    display: inline-block;
    vertical-align: middle;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.main {
    flex: 1;
}

.container {
    max-width: 1264px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

.text-center {
    text-align: center;
}

@media (max-width: 1199.98px) {
    h1 {
        font-size: 68px;
        line-height: 78px;
    }

    h2 {
        font-size: 40px;
        line-height: 68px;
    }
    h3 {
        font-size: 34px;
        line-height: 44px;
    }
}

@media (max-width: 991.98px) {
    h1 {
        font-size: 64px;
        line-height: 76px;
    }

    h2 {
        font-size: 40px;
        line-height: 64px;
    }
    h3 {
        font-size: 32px;
        line-height: 40px;
    }
    h4 {
        font-size: 25px;
    }
}

@media (max-width: 767.98px) {
    body {
        font-size: 16px;
        line-height: 1.5;
    }
    h1 {
        font-size: 52px;
        line-height: 60px;
    }

    h2 {
        font-size: 37px;
        line-height: 54px;
    }

    h3 {
        font-size: 28px;
        line-height: 38px;
    }
    h4 {
        font-size: 22px;
        line-height: 22px;
    }
}
@media (max-width: 575.98px) {
    .container {
        padding: 0 15px;
    }
    h1 {
        font-size: 40px;
        line-height: 50px;
        letter-spacing: -2px;
    }
    h2 {
        font-size: 30px;
        line-height: 42px;
    }
    h3 {
        font-size: 26px;
        line-height: 36px;
    }
    h4 {
        font-size: 20px;
    }
    p {
        font-size: 20px;
        line-height: 30px;
    }
}
