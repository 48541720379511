.footer {
    background-color: #343434;
    padding-top: 61px;
    padding-bottom: 52px;
    color: #fefefe;
}

.footer__social {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 15px;
}

.footer__social h5 {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;
}

.footer__social-links {
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer__social-links a {
    display: inline-block;
    transition: all 0.25s ease;
}

.footer__social-links a:hover {
    opacity: 0.5;
}

.footer__text {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.footer__text p {
    font-size: 12px;
    line-height: 1.4;
}

.footer__text-terms {
    display: flex;
    align-items: center;
    gap: 33px;
}

.footer__text-terms a {
    display: inline-block;
    color: #fefefe;
    font-size: 12px;
    line-height: 1.4;
    transition: all 0.25s ease;
}

.footer__text-terms a:hover {
    color: #cbcbcb;
}

@media (max-width: 991.98px) {
    .footer {
        padding-top: 50px;
        padding-bottom: 32px;
    }
    .footer__social {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 20px;
    }

    .footer__social-links {
        margin-top: 20px;
    }

    .footer__text {
        flex-direction: column-reverse;
        align-items: center;
        text-align: center;
    }

    .footer__text-terms {
        margin-bottom: 20px;
    }
}
