.section {
    padding: 30px 20px 30px 20px;
}

.header {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0px 0px 0px 0px;
}

.header2 {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0px 0px 30px 0px;
}

.image {
    height: 280px;
    @media (max-width: 600px) {
      height: 200px
    };
    @media (max-width: 500px) {
      height: 150px
    }
}

.demo_wrap {
    display: flex;
    justify-content: center;
    width: 70%;
}

.demo_container {
    display: flex;
    justify-content: center;
}