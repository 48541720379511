.section {
    position: relative;
    padding-bottom: 159px;
    margin-top: 50px;
}

.section-background {
    position: absolute;
    left: 0;
    top: 0;
    /* background-image: url('./../../assets/images/session-bg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top center;
    width: 100%;
    height: 632px;
}

.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1206px;
    margin: 0 auto;
    padding: 0 20px;
    z-index: 2;
}

.session {
    width: 100%;
    transform: translateY(-120px);
    margin-bottom: 37px;
}

.faq {
    max-width: 980px;
    width: 100%;
    padding: 50px 0 56px 0;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.list__item {
    width: 100%;
    background: #ffffff;
    border: 1px solid #d3d3d3;
    border-radius: 16px;
    margin-bottom: 14px;
}

.list__item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 40px 26px 40px;
    cursor: pointer;
}

.list__item-header p {
    flex: 1;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -1px;
    color: #000000;
    margin-right: 20px;
}

.icon {
    display: inline-block;
    transition: all 0.2s linear;
}

.icon.rotate {
    transform: rotate(-180deg);
}

.list__answers {
    padding-top: 2px;
    height: 0;
    overflow: hidden;
}

.list__answers.show {
    height: auto;
}

.list__answers-content {
    border-top: 1px solid #d3d3d3;
    padding: 24px 40px;
}

.questions {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.questions h5 {
    font-weight: 400;
    font-size: 27px;
    line-height: 34px;
    letter-spacing: -0.56px;
    margin-bottom: 20px;
}

.questions p {
    font-size: 17px;
    line-height: 27px;
    color: #263238;
    opacity: 0.6;
    margin-bottom: 35px;
}

.questions a {
    display: inline-block;
    text-decoration: none;
    background-color: #ffffff;
    border: 1px solid rgba(38, 50, 56, 0.15);
    border-radius: 9.6px;
    font-weight: 500;
    font-size: 17px;
    line-height: 27px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #263238;
    padding: 13px 26px;
    transition: all 0.25s ease;
}

.questions a:hover {
    background-color: #f4f4f4;
}

@media (max-width: 991.98px) {
    .section {
        padding-bottom: 100px;
    }

    .faq {
        padding: 50px 0;
    }

    .list__item-header {
        padding: 16px 20px 14px 20px;
    }

    .list__item-header p {
        font-size: 18px;
        line-height: 28px;
    }

    .list__answers-content {
        padding: 20px;
        padding-top: 18px;
    }

    .questions h5 {
        margin-bottom: 14px;
    }
    .questions p {
        margin-bottom: 30px;
    }

    .questions a {
        border-radius: 8px;
        font-size: 16px;
        line-height: 24px;
        padding: 10px 20px;
    }
}

@media (max-width: 767.98px) {
    .session {
        transform: translateY(-140px);
        margin-bottom: 0;
    }
    .faq {
        padding: 40px 0;
    }
    .list__item {
        margin-bottom: 12px;
    }
    .list__item-header {
        padding: 12px 16px 10px 16px;
    }

    .list__item-header p {
        font-size: 16px;
    }

    .list__answers-content {
        padding: 16px;
        padding-top: 14px;
    }
    .questions h5 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 12px;
    }
    .questions p {
        font-size: 16px;
        line-height: 14px;
    }
}

@media (max-width: 575.98px) {
    .container {
        padding: 0 15px;
    }
}