.section {
    padding: 50px 20px 30px 20px;
}

.header {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0px 0px 0px 0px;
}

.header2 {
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0px 0px 50px 0px;
}

.imgContainer {
    display: flex;
    justify-content: center;
}

.testimonialImg {
    display: flex;
    justify-content: center;
    width: 75%;
}

@media (max-width: 600px) {
    .testimonialImg {
        width: 100%
    }
}