.btn {
    display: inline-block;
    background-color: #398fe5;
    border: 1px solid #398fe5;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.4;
    color: #fefefe;
    padding: 8px 23px;
    transition: all 0.3s ease;
}

.btn:hover {
    background-color: transparent;
    color: #398fe5;
}

@media (max-width: 575.98px) {
    .btn {
        padding: 7px 20px;
    }
}
