.section {
    padding-top: 222px;
    padding-bottom: 142px;
    color: #fefefe;
}

.section-content {
    display: flex;
    align-items: flex-start;
    gap: 68px;
}

.section__image {
    padding: 124px 0 0 36px;
}

.section__image img {
    max-width: 387px;
    width: 100%;
    height: auto;
}

.section__text {
    flex: 1;
}

.section__text h2 {
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 30px;
}

.section__text p {
    font-size: 36px;
    font-weight: 400;
    line-height: 1.4;
    margin-bottom: 40px;
}

.section__text p:last-child {
    margin-bottom: 0;
}

.section__text p a {
    color: #398fe5;
}

@media (max-width: 1199.98px) {
    .section-content {
        gap: 50px;
    }
    .section__image {
        padding: 124px 0 0 0;
    }
    .section__image img {
        max-width: 360px;
    }
    .section__text h2 {
        margin-bottom: 20px;
    }
    .section__text p {
        font-size: 32px;
        margin-bottom: 32px;
    }
}

@media (max-width: 991.98px) {
    .section {
        padding-top: 200px;
        padding-bottom: 120px;
    }
    .section-content {
        flex-direction: column;
    }
    .section__image {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0;
    }
    .section__text h2 {
        font-size: 32px;
    }
    .section__text p {
        font-size: 28px;
    }
}

@media (max-width: 767.98px) {
    .section {
        padding-top: 160px;
        padding-bottom: 100px;
    }
    .section__text h2 {
        font-size: 28px;
    }
    .section__text p {
        font-size: 24px;
        margin-bottom: 24px;
    }
}

@media (max-width: 575.98px) {
    .section__text p {
        font-size: 20px;
    }
}
