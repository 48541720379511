.error {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
}

.error__conent {
    max-width: 510px;
    width: 100%;
    margin: 0 auto;
}

.error__conent h1 {
    color: #398fe5;
    font-weight: 800;
}

.error__conent p {
    font-size: 18px;
    color: #c6c6c6;
    margin-top: 10px;
    margin-bottom: 16px;
}

.error__conent button {
    display: inline-block;
    background-color: #398fe5;
    border: 1px solid #398fe5;
    border-radius: 30px;
    font-size: 16px;
    line-height: 1.4;
    font-weight: 700;
    color: #fefefe;
    padding: 8px 20px;
    transition: all 0.25s;
}

.error__conent button:hover {
    color: #398fe5;
    background-color: transparent;
}

@media (max-width: 575.98px) {
    .error__conent h2 {
        font-size: 26px;
    }
    .error__conent p {
        font-size: 16px;
    }
}
