.section {
    margin-top: 30px;
    padding: 53px 0;
}

.container {
    max-width: 1353px;
    margin: 0 auto;
    padding: 0 20px;
}

.container h2 {
    text-align: center;
    margin-bottom: 50px;
}

.header {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0px 0px 0px 0px;
}

.header2 {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 0px 0px 50px 0px;
}

.content {
    width: 100%;
    height: 584px;
    border-radius: 24px 24px 0px 0px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.text-blue{
    color: #398FE5;
  }

@media (max-width: 991.98px) {
    .content {
        height: 500px;
    }
}

@media (max-width: 767.98px) {
    .section {
        padding: 80px 0;
    }
    .container h2 {
        margin-bottom: 60px;
    }
    .content {
        height: 400px;
    }
}

@media (max-width: 575.98px) {
    .container {
        padding: 0 15px;
    }
    .container h2 {
        margin-bottom: 40px;
    }
    .content {
        height: 360px;
    }
}
