
.schools_header {
    height: 80px;
    width: 100%;
    position: fixed;
    z-index: 20;
    top: 20px;
  }
  
  @media only screen and (max-width: 40em) {
    .schools_header {
      top: 0px;
    }
  }
  
  .schools_header .header_nav {
    background: #1E2026;
    -webkit-box-shadow: 0px 4px 25px rgba(15, 15, 15, 0.25);
    box-shadow: 0px 4px 25px rgba(15, 15, 15, 0.25);
    height: 75px;
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 10px;
  }
  
  @media only screen and (min-width:40.063em) {
    .schools_header .header_nav .mobile_nav {
      display: none;
    }
  }
  
  .schools_header .header_nav .mobile_nav .nav_menu {
    border-radius: 10px;
    height: 80%;
    width: 80%;
    background: #4098F9;
    z-index: 2;
    position: fixed;
    display: none;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    padding-left: 0;
    list-style-type: none;
    padding: 1em;
  }
  
  .schools_header .header_nav .mobile_nav .nav_menu .mobile_home {
    color: white;
    font-size: 1.0em;
  }
  
  .schools_header .header_nav .mobile_nav .nav_menu .mobile_home:hover {
    color: #333332;
  }
  
  .schools_header .header_nav .mobile_nav .nav_menu .mobile_about {
    color: white;
    font-size: 1.0em;
    padding-top: 1.0em;
  }
  
  .schools_header .header_nav .mobile_nav .nav_menu .mobile_about:hover {
    color: #333332;
  }
  
  .schools_header .header_nav .mobile_nav .nav_menu .mobile_bookshelf {
    color: white;
    font-size: 1.0em;
    padding-top: 1.0em;
    padding-bottom: 5.0em;
  }
  
  .schools_header .header_nav .mobile_nav .nav_menu .mobile_bookshelf:hover {
    color: #333332;
  }
  
  .schools_header .header_nav .mobile_nav .nav_menu .mobile_login {
    border-radius: 40px;
    background-color: white;
    width: 40%;
    height: 2.5em;
    color: #111217;
    padding-top: 0.75em;
    font-size: 1.0em;
  }
  
  .schools_header .header_nav .mobile_nav .nav_menu .mobile_login:hover {
    color: #333332;
  }
  
  .schools_header .header_nav .mobile_nav .nav_menu .close_menu {
    color: white;
    padding-bottom: 20px;
    padding-top: 3.0em;
  }
  
  .schools_header .header_nav .mobile_nav .nav_menu .close_menu:hover {
    color: #333332;
  }
  
  .schools_header .header_nav .mobile_nav .open_menu {
    color: white;
    padding-bottom: 20px;
  }
  
  .schools_header .header_nav .mobile_nav .open_menu:hover {
    color: #333332;
  }
  
  .schools_header .header_nav .logo {
    background-repeat: no-repeat;
    background-size: contain;
  }
  
  @media only screen and (min-width:64.063em) {
    .schools_header .header_nav .logo {
      background-image: url('./../assets/images/full_logo.svg');
      height: 35px;
      width: 180px;
    }
  }
  
  @media only screen and (max-width: 40em) {
    .schools_header .header_nav .logo {
      color: white;
      background-image: url('./../assets/images/icon_primary.png');
      height: 35px;
      width: 35px;
    }
  }
  
  @media only screen and (min-width:40.063em) and (max-width:64em) {
    .schools_header .header_nav .logo {
      color: white;
      background-image: url('./../assets/images/icon_primary.png');
      height: 35px;
      width: 65px;
    }
  }
  
  @media only screen and (max-width: 40em) {
    .schools_header .header_nav nav {
      display: none;
    }
  }
  
  .schools_header .header_nav nav a {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 700;
    letter-spacing: 0px;
  }
  
  .schools_header .header_nav nav a.home {
    padding: 30px 30px;
  }
  
  .schools_header .header_nav nav a.home:hover {
    color: #333332;
  }
  
  .schools_header .header_nav nav a.about {
    padding: 30px 30px;
  }
  
  @media only screen and (min-width:40.063em) and (max-width:64em) {
    .schools_header .header_nav nav a.about {
      padding: 20px 20px;
    }
  }
  
  .schools_header .header_nav nav a.about:hover {
    color: #333332;
  }
  
  .schools_header .header_nav nav a.bookshelf {
    padding: 30px 30px;
  }
  
  @media only screen and (min-width:40.063em) and (max-width:64em) {
    .schools_header .header_nav nav a.bookshelf {
      padding: 20px 20px;
    }
  }
  
  .schools_header .header_nav nav a.bookshelf:hover {
    color: #333332;
  }
  
  .schools_header .header_nav .buttons a.log_in {
    text-decoration: none;
    color: #FFFFFF;
    background: rgba(255, 255, 255, 0.12);
    border-radius: 8px;
    font-weight: 700;
    letter-spacing: 0px;
    padding: 15px 30px 15px 40px;
    margin-right: 10px;
  }
  
  @media only screen and (max-width: 40em) {
    .schools_header .header_nav .buttons a.log_in {
      background: #1E2026;
      padding: 10px;
    }
  }
  
  .schools_header .header_nav .buttons a.log_in:hover {
    color: #333332;
  }
  
  .schools_header .header_nav .buttons a.signup {
    text-decoration: none;
    color: #FFFFFF;
    background: #4098F9;
    border-radius: 8px;
    font-weight: 700;
    letter-spacing: 0px;
    padding: 15px 30px 15px 30px;
  }
  
  @media only screen and (max-width: 40em) {
    .schools_header .header_nav .buttons a.signup {
      display: none;
    }
  }
  
  .schools_header .header_nav .buttons a.signup:hover {
    color: #333332;
  }